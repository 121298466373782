import {post,get} from '../utils/axios'

// 获取银行信息
export function getCmbPayInfo(params) {
  return post('order/getCmbPayInfo', params)
}

// 获取贵州银行信息
export function getGzPayResult(params) {
  return post('order/getGzPayResult', params)
}