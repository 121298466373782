<template>
  <div class="container">
    <SimpleHeader />
    <div class="nogetcode">
      <div class="nogetcode-title">手机收不到短信验证码的原因及解决办法：</div>
      <div class="ht-15"></div>
      <div class="nogetcode-num">一、用户手机号码被运营商屏蔽</div>
      <div >用户曾将此类通知短信向运营商投诉为垃圾短信，使得当前通道被运营商屏蔽。</div>
      <div class="nogetcode-solution">解决方法：自己联系运营商解决，或点击下面按钮联系官方客服协助处理</div>
      <div class="nogetcode-num">二、 手机短信安全软件等黑名单设置</div>
      <div >用户曾在手机短信中设置了比较严苛的拦截规则。</div>
      <div class="nogetcode-solution">解决方法： 查看短信黑名单设置，菜单->骚扰拦截->设置，分别查看拦截模式与黑白名单是否有不允许短信到达的号码</div>
      <div class="nogetcode-num">三、手机短信发送超过当天发送频率限制</div>
      <div >为避免短信轰炸，以及资源浪费，通道对每个手机号码每天最多发送40条短信，每小时内不能超过10条，每分钟不超过2条。</div>
      <div class="nogetcode-solution">解决方法：根据上述时间限制调整重试时间</div>
    </div>
    <div class="foot-btns">
      <div class="foot-btnbox">
      <!-- <button class="foot-btn" @click="loginOut">联系客服</button> -->
        <a href="tel:" class="foot-btn">联系客服</a>
      </div>

    </div>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
export default {
  name: 'tips',
  components: {
    SimpleHeader
  },
  setup() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #fff;
  min-height: 100%;
}
.foot-btns{
  position: initial;
  left: 0;
  transform: translateX(0);
  margin-top: 30px;
}
.ht-15{
  height: 15px;

}

.nogetcode{
  padding: 20px;
  border-top: 1px solid #f8f8f8;
}
.nogetcode-title{
  font-size: 17px;
  color: #333333;
  font-weight: 700;
}
.nogetcode-num{
  font-size: 14px;
  color: #333333;
  padding: 10px 0;
  font-weight: 700;
}
.nogetcode-solution{
  font-size: 14px;
  color: #333333;
  padding: 10px 0;
}
.nogetcode-contact{
  width: 60%;
  height: 40px;
  line-height: 40px;
  background: #FAD232;
  border-radius: 4px;
  text-align: center;
  margin: 40px auto 0;
  font-size: 15px;
  color: #333333;
}
</style>