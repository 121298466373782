<template>
  <div class="container">
    <SimpleHeader />
    <div class="pay-result">
      <img class="pay-img" :src="info.image" />
      <div class="pay-title" v-if="info.title">{{info.title}}</div>
      <div class="pay-text" v-if="info.note">{{info.note}}</div>
      <div class="pay-btns">
        <div class="pay-btn" @click="openPage('home')">{{info.btn1}}</div>
        <div class="pay-btn" @click="openPage('order')">{{info.btn2}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted,reactive,toRefs, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import { getSituationData } from '@/api/statis'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
export default {
  name: 'statis',
  components: {
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const { reLaunch,switchTabWx } = usePage()
    const route = useRoute()
    const state = reactive({
      info:{
        btn1: "返回首页",
        btn1_url: "",
        btn2: "查看订单",
        btn2_url: "/packages/pack-B/pages/order/order",
        cmb: "https://x.jzbull.com/hawkeye/pay.png",
        note: "订单支付成功！",
        image:'https://x.jzbull.com/hawkeye/pay.png',
        title: "恭喜你",
      }
    })
    const openPage = (type = 'order') => {
      // if (route.query.order_id) {
      //   redirectTo(`/packages/pack-B/pages/order/order-detail?order_id=${route.query.order_id}&from_path=&afterSaleKey=0`)
      // } else {
      //   redirectTo(`/packages/pack-B/pages/order/order?typeIndex=0`)

      // }
      if (type == 'home') {
        switchTabWx('/pages/index/index')
      } else {
        reLaunch(`/packages/pack-B/pages/order/order?typeIndex=0`,{type:'jump'})
      }
    }
    onMounted(async () => {
      // getData()
    })
    const getData = async ()=> {

      let params = {

      }
      let [error,result] = await to(getSituationData(params))
      _appLoadFanish()
      state.loading = false
      if(error) return;
      let {code, res} = result;
      if (code === 0) {
        state.tabs = res.tabs
        state.list = res.list && res.list.map(v=>{
          v.currentRate = 0
          return v
        })
      }
    }
    return {
     ...toRefs(state),
     openPage
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';

.container {
  background: #fff;
  .pay-result{
    text-align: center;
    .pay-img{
      width: 180px;
      height: 180px;
      margin: 30px auto 0;
    }
    .pay-title{
      font-size: 18px;
      color: #333333;
      margin-top: 20px;

    }
    .pay-text{
      font-size: 14rpx;
      color: #999999;
      margin-top: 10px;
      text-align: center;
    }
    .pay-btns{
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
    .pay-btn{
      background: #F5F6F7;
      width: 110px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
    .pay-btn+.pay-btn{
      margin-left: 15px;
      background: #FEC80A;
    }
    .pay-btn:last-of-type{
      background: #FEC80A;
    }
  }
}

</style>
