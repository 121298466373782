<template>
  <div class="container" :class="bankType == 'gzbank'?'gzbank':''">
    <SimpleHeader />
    <div class="gzbank" v-if="bankType == 'gzbank'">
      <img class="pay_down_icon" src="https://x.jzbull.com/dsx-h5/time_down.gif" alt="">
      <div class="pay_down_txt">支付结果查询中</div>
      <div class="pay_down_desc">几秒钟后将自动返回支付结果，请不要关闭页面</div>
    </div>
    <div class="loading" v-else>
      <img class="loading-img" src="https://x.jzbull.com/dsx-h5/bank-yi.png" alt="">
      <div class="loading-txt">支付跳转中</div>
      <div class="square-container">
        <span class="square one"></span>
        <span class="square two"></span>
        <span class="square three"></span>
      </div>
    </div>

  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted,reactive,toRefs, inject, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import { getCmbPayInfo,getGzPayResult } from '@/api/bank'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
export default {
  name: 'statis',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      jsonRequestData:'',
      system_order_id:'',
      bankInfo:{
          'cmb':'https://x.jzbull.com/dsx-h5/bank-yi.png'
        }
    })
    const bankType = ref(route.query?.pay_type)
    const count = ref(0)
    onMounted(async () => {
      console.log(bankType)
      if (bankType.value == 'gzbank'){
        gzGetPayInfo()
      } else {
        getData()
      }
    })
    const getData = async ()=> {
      let params = {
        type:route.query.type,
        typeid:route.query.typeid
      }
      let [error,result] = await to(getCmbPayInfo(params))
      _appLoadFanish()
      state.loading = false
      if(error) {
        router.push(`/pay-error?order_id=${error.res.order_id}&msg=${error.msg}`)
        return
      };
      let {code, res, msg} = result;
      if (code === 0) {
        state.system_order_id = res.system_order_id;
        let values = [
          {type:'hidden',name:'charset',value:'UTF-8'},
          {type:'hidden',name:'jsonRequestData',value:res.jsonRequestData}
        ]
        pay({values,url:res.formAction});
      } else {
        router.push(`/pay-error?order_id=${res.order_id}&msg=${msg}`)
        // Toast(msg||'支付失败');
      }
    }
    const pay = ({values,url}) => {
      // 创建一个 form
        var myform = document.createElement("form");
        myform.id = "myform";
        myform.name = "myform";
        // 添加到 body 中
        document.body.appendChild(myform);
        for(let i = 0; i < values.length; i++){
          let item = values[i];
          // 创建一个输入
          let input = document.createElement("input");
          // 设置相应参数
          input.type = item.type;
          input.name = item.name;
          input.value = item.value;
          // 将该输入框插入到 form 中
          myform.appendChild(input);
        }
        // form 的提交方式
        myform.method = "POST";
        // form 提交路径
        myform.action = url;
        // 对该 form 执行提交
        myform.submit();
        // 删除该 form
        document.body.removeChild(myform);
    }
    // 贵州银行轮询
    const gzGetPayInfo = async ()=> {
      let [error,result] = await to(getGzPayResult({order_sn:route.query?.order_sn}));
      if(error) {
        router.push(`/pay-error`)
        return
      };
      let {is_success,url} = result.res;
      count.value++;
      if (is_success){
        router.push(url)
      } else {
        if (count.value>3) {
          router.push(`/pay-error`)
          return;
        }
        setTimeout(() => {
          gzGetPayInfo();
        }, 1500);
      }
    }
    return {
     ...toRefs(state),
     bankType
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';

.container {
    &.gzbank{
      background: #fff;
      text-align: center;
    }
    .pay_down_icon{
      margin-top: 15%;
      width: 100px;
      height: 100px;
    }
    .pay_down_txt{
      font-weight: 500;
      font-size: 16px;
      // margin-top: -5px;
      color:#333;
    }
    .pay_down_desc{
      font-weight: 400;
      font-size: 15px;
      margin-top: 20px;
      color:#999;
    }
    .square-container {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
    }
    .square {
        height: 5px;
        width: 5px;
        background-color: #fff;
        float: left;
        margin: 0 2.5px;
        border-radius: 50%;
        -webkit-animation-name: loading;
        -webkit-animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
    }
      .one {
          -webkit-animation-delay: 0.5s;
      }
      .two {
          -webkit-animation-delay: 1s;
      }
      .three {
          -webkit-animation-delay: 1.5s;
      }
      @-webkit-keyframes loading {
          30% {
              opacity: 0.5;
          }
      }
  .loading{
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -80px;
    display: inline-block;
    width: 160px;
    height: 140px;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    text-align: center;
    border-radius: 12px;
    &-img{
      width: 50px;
      height: 50px;
      margin:24px 0 12px;
    }
    &-txt{
      line-height: 1;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}

</style>
